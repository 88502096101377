import React, { useState, useEffect, useContext } from 'react'
import logo from "../../assets/img/logo.svg"
import coinLogo from '../../assets/img/Header_Icon_Coin_Flip.svg'
import coinLogoSelected from '../../assets/img/Header_Icon_Coin_Flip_Selected.svg'
import diceLogo from '../../assets/img/Header_Icon_Dice.svg'
import diceLogoSelected from '../../assets/img/Header_Icon_Dice_Selected.svg'
import twoDiceLogo from '../../assets/img/Header_Icon_Two_Dice.svg'
import twoDiceLogoSelected from '../../assets/img/Header_Icon_Two_Dice_Selected.svg'
import trustrollLogo from '../../assets/img/Header_Icon_Trustroll.svg'
import trustrollLogoSelected from '../../assets/img/Header_Icon_Trustroll_Selected.svg'
import betaText from '../../assets/img/beta.svg'
import ButtonTransparent from './ButtonTransparent'
import { Link, NavLink } from 'react-router-dom';
import docsHeader from '../../assets/img/docs-header.svg'
import docsHeaderSelected from '../../assets/img/docs-header-selected.svg'
import BetContext from '../../BetContext'
import ConnectWallet from '../wallet/ConnectWallet'
import constants from "../../constance"
import linkArrow from "../../assets/img/link-arrow.svg"
import greenCircle from "../../assets/img/green-circle.svg"
import { useTranslation, Trans } from 'react-i18next';
import BlockchainContext from "../../BlockchainContext";


const { NET } = constants;
const mode = process.env.REACT_APP_MODE;


export default function NavBar({ setModulo, modulo, setHasPlayed, setWarning, switchNetBTN, setConnectError }) {

    const { t } = useTranslation(); // multilang

    const { setGameResult } = useContext(BetContext)

    const games = [
        { 'gameName': 'Coin flip', 'gameLangText': 'coinflip', 'gameLogo': coinLogo, 'gameLogoSelected': coinLogoSelected, 'gameModulo': 2, gamelink: '/gamepage/coin_flip' },
        { 'gameName': 'Dice', 'gameLangText': 'dice', 'gameLogo': diceLogo, 'gameLogoSelected': diceLogoSelected, 'gameModulo': 6, gamelink: '/gamepage/dice' },
        { 'gameName': 'Two dice', 'gameLangText': 'twodice', 'gameLogo': twoDiceLogo, 'gameLogoSelected': twoDiceLogoSelected, 'gameModulo': 36, gamelink: '/gamepage/two_dice' },
        { 'gameName': 'Trustroll', 'gameLangText': 'trustroll', 'gameLogo': trustrollLogo, 'gameLogoSelected': trustrollLogoSelected, 'gameModulo': 100, gamelink: '/gamepage/trustroll' }
    ]




    const mobileMenu = [
        // { 'gameName': 'Terms of service', 'gameLogo': '', 'gameLogoSelected': '', 'gameModulo': 400 },
        { 'gameName': 'FAQ', 'gameLogo': '', 'gameLogoSelected': '', 'gameModulo': 401 }
    ]

    const [mobile, setMobile] = useState(false);

    let defaultNet = NET.SWITCH_BUTTON.TEXT; //:FIX

    let [selectNet, setSelectNet] = useState(defaultNet)

    function togglenet(e) {
        setSelectNet(e.target.innerHTML)
    }

    useEffect(() => {
        if (mobile) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [mobile])

    const changeLink = (e) => { // меняем адрес сайта
        for (let i = 0; i < e.target.selectedOptions.length; i++) {
            console.log(e.target.selectedOptions[i].value);
            window.location.href = e.target.selectedOptions[i].value
        }
    }

    const options = [
        { net: 'BSC Mainnet' },
        { net: 'BSC Testnet' },
    ]

    const optionKeys = Object.keys(options)

    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown)
    }
    let selectedOption = '';
    let setSelectedOption = event => {
        selectedOption = event.target.getAttribute('value')
        setShowDropdown(false)
    }


    return (
        <div className='nav-bar'>
            <div className='site-width'>

                <div className='nav-bar-body'>

                    <Link to="/" className='nav-bar-logo'
                        onClick={e => {
                            setModulo(0)
                            setMobile(false)
                            setHasPlayed(false)
                            // setGameResult({})

                        }}>
                        <img className='nav-bar-img' src={logo} type="image/svg+xml" alt='logo' />


                    </Link>

                    {/* <img src={betaText} alt="Text." className="img-text" /> */}

                    <ul className='nav-bar-menu'>
                        {games.map(game => <Link to={game.gamelink} className='nav-bar-menu-item'
                            key={game.gameModulo}
                            onClick={() => {
                                setModulo(game.gameModulo)
                                setHasPlayed(false)
                                setConnectError(false)

                            }}
                        >
                            <div className={modulo === game.gameModulo ? "img-block-active" : "img-block"}></div>
                            <span className={modulo === game.gameModulo ? 'nav-bar-menu-item-active' : 'nav-bar-menu-item-text'}>{t(`menu.${game.gameLangText}`)}</span>
                        </Link>)}
                        <a href={t(`docs`)}
                            className='nav-bar-menu-item doc-link' target="blank">
                            {/* <img src={docsHeader} alt="Document" className='doc-header'/>  */}
                            <div className="img-block"></div>
                            <Trans i18nKey="menu.docs">Docs</Trans></a>

                        {/* <ul className='net-toggler'>
                            <li><span className='net-toggler-item link-logo'>{selectNet}</span>
                                <ul className='net-switcher'>
                                    <span className='select-text'><Trans i18nKey="menu.net">Select a network</Trans></span>
                                    <li className='net-toggler-item'>
                                        <p className='link-logo'><a href={switchNetBTN.LINKS.LINK} onClick={togglenet} value="BSC Mainnet" >BSC Mainnet</a>
                                            {defaultNet == "BSC Mainnet" ? <img src={greenCircle} alt="Green circle." /> : ''}</p>
                                        <p className='bscscan'><a href="https://bscscan.com/address/0x08b1bd3b20ff82ad8bfe97f603a0bc8bb622542a" target="_blank" >BscScan</a><a href="https://bscscan.com/address/0x08b1bd3b20ff82ad8bfe97f603a0bc8bb622542a" target="_blank"><img src={linkArrow} alt="Arrow." /></a></p>
                                    </li>
                                    <li className='net-toggler-item'>
                                        <p className='link-logo'><a href={switchNetBTN.LINKS.LINK_TEST} onClick={togglenet} value="BSC Testnet" >BSC Testnet</a>
                                            {defaultNet == "BSC Testnet" ? <img src={greenCircle} alt="Green circle." /> : ''}</p>
                                        <p className='bscscan'><a href="https://testnet.bscscan.com/address/0xdec2a3a7170cfdb48e75985d928babdd1c790fd8" target="_blank" >BscScan</a><a href="https://testnet.bscscan.com/address/0xdec2a3a7170cfdb48e75985d928babdd1c790fd8" target="_blank"><img src={linkArrow} alt="Arrow." /></a></p>

                                    </li>
                                </ul>
                            </li>
                        </ul> */}

                        <ConnectWallet
                            position='NavBar'
                        >
                        </ConnectWallet>


                    </ul>
                    {/* <ul className='net-toggler net-toggler-mobile'>
                        <li><span className='net-toggler-item link-logo'>{selectNet}</span>
                            <ul className='net-switcher'>
                                <span className='select-text'><Trans i18nKey="menu.net">Select a network</Trans></span>
                                <li className='net-toggler-item'>
                                    <p className='link-logo'><a href={switchNetBTN.LINKS.LINK} onClick={togglenet} value="BSC Mainnet" >BSC Mainnet</a>
                                        {defaultNet == "BSC Mainnet" ? <img src={greenCircle} alt="Green circle." /> : ''}</p>
                                    <p className='bscscan'><a href="https://bscscan.com/address/0x08b1bd3b20ff82ad8bfe97f603a0bc8bb622542a" target="_blank" >BscScan</a><a href="https://bscscan.com/address/0x08b1bd3b20ff82ad8bfe97f603a0bc8bb622542a" target="_blank"><img src={linkArrow} alt="Arrow." /></a></p>
                                </li>
                                <li className='net-toggler-item'>
                                    <p className='link-logo'><a href={switchNetBTN.LINKS.LINK_TEST} onClick={togglenet} value="BSC Testnet" >BSC Testnet</a>
                                        {defaultNet == "BSC Testnet" ? <img src={greenCircle} alt="Green circle." /> : ''}</p>
                                    <p className='bscscan'><a href="https://testnet.bscscan.com/address/0xdec2a3a7170cfdb48e75985d928babdd1c790fd8" target="_blank" >BscScan</a><a href="https://testnet.bscscan.com/address/0xdec2a3a7170cfdb48e75985d928babdd1c790fd8" target="_blank"><img src={linkArrow} alt="Arrow." /></a></p>

                                </li>
                            </ul>
                        </li>
                    </ul> */}


                    <div className={mobile ? 'nav-bar-mobile-button nav-bar-mobile-open' : 'nav-bar-mobile-button nav-bar-mobile-closed'} onClick={() => setMobile(!mobile)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                    <ul className={mobile ? 'nav-bar-mobile site-width' : 'hide'}>
                        {games.map(game => <Link to={game.gamelink} className='nav-bar-menu-item'
                            key={game.gameModulo}
                            onClick={() => {
                                setModulo(game.gameModulo)
                                setMobile(false)
                                setHasPlayed(false)
                                setConnectError(false)
                            }} >

                            <div className={modulo === game.gameModulo ? "img-block-active" : "img-block"}></div>
                            <span className={modulo === game.gameModulo ? 'nav-bar-menu-item-active' : 'nav-bar-menu-item-text'}>{t(`menu.${game.gameLangText}`)}</span>
                        </Link>)}
                        {/* <a href="https://docs.trustroll.win/trustroll/" className='doc-link' target="blank">
                            <div className="img-block"></div>
                            <Trans i18nKey="menu.docs">Docs</Trans></a> */}
                        {mobileMenu.map(game => <li className='nav-bar-menu-item'
                            key={game.gameModulo}
                            onClick={() => {
                                setModulo(game.gameModulo)
                                setMobile(false)
                                setHasPlayed(false)
                                setConnectError(false)

                            }} >
                            <span className={modulo === game.gameModulo ? 'nav-bar-menu-item-active' : 'nav-bar-menu-item-text'}>{game.gameName} </span>
                        </li>)}

                        <ConnectWallet
                            position='NavBar'
                        >
                        </ConnectWallet>
                    </ul>
                </div>

            </div>
        </div>
    )
}