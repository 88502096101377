import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import axios from "axios";
// TODO: Change TrustRoll in our project
import TrustRoll from "./contracts/TrustRoll.json";
// import BlockchainContext from "./BlockchainContext.js";
// Styles
import "./App.scss";
// TODO:
// import GamePanel from "./components/GamePanel.jsx";
import TopHeader from "./components/header/TopHeader";
import Header from "./components/header/Header";
import Footer from "./components/Footer";
import TOS from "./components/TOS";
import FAQ from "./components/FAQ";
// import WelcomePage from "./components/WelcomePage/WelcomePage";
import Page404 from "./components/404";

import constants from "./constance";
// import { keyframes } from "styled-components";
import BlockchainContext from "./BlockchainContext.js";
import BetContext from "./BetContext";
import GameCalculate from "./utils/gameCalculation";
import SaveGamblerChoice from "./utils/saveGamerChoice";
//TODO:In this import, you need to leave only the NET object
const { NET } = constants;

function MyLoadingComponent() {
  return <div>Loading...</div>;
}

const WelcomePage = Loadable({
  loader: () => import("./components/WelcomePage/WelcomePage"),
  loading: MyLoadingComponent,
});
const GamePanel = Loadable({
  loader: () => import("./components/GamePanel"),
  loading: MyLoadingComponent,
});

const CONTRACT_ADDRESS = NET.BLOCKCHAIN.CONTRACT_ADDRESS;
const PORT = NET.SERVER_API_URL;
// const CHAIN_ID = NET.BLOCKCHAIN.CHAIN_ID;
const NETWORK_NAME = NET.BLOCKCHAIN.NETWORK_NAME;
const BASE_SCAN_URL = NET.BASE_SCAN_URL;
// const RPC_URL = NET.BLOCKCHAIN.RPC_URL;
// const NETWORKS = NET.BLOCKCHAIN[0];

// FIXME:
function App() {
  const blockchainContext = useContext(BlockchainContext);
  // let details = {};
  const ropsten = `https://ropsten.etherscan.io/`;
  const [modulo, setModulo] = useState(
    localStorage.getItem("local-storage-modulo-data") === 0
      ? 0
      : +localStorage.getItem("local-storage-modulo-data")
  );
  const [web3AndContractCredentials, setWeb3AndContractCredentials] = useState({
    web3: undefined,
    accounts: undefined,
    contract: undefined,
    lockedInBets: 0,
    contractBalance: undefined,
    gamblerBalance: undefined,
    maxProfit: undefined,
    jackpotSize: 0,
  });

  let {
    web3,
    accounts,
    contract,
    lockedInBets,
    contractBalance,
    gamblerBalance,
    maxProfit,
    jackpotSize,
  } = web3AndContractCredentials;

  // const [owner, setOwner] = useState(undefined);
  // const [croupier, setCroupier] = useState(undefined);
  // const [web3, setWeb3] = useState(undefined);
  // const [accounts, setAccounts] = useState(undefined);
  // const [contract, setContract] = useState(undefined);
  // const [lockedInBets, setLockedInBets] = useState(0);
  // const [contractBalance, setContractBalance] = useState(undefined);

  // const [maxProfit, setMaxProfit] = useState(undefined);
  // const [jackpotSize, setJackpotSize] = useState(0);
  // const [warning, setWarning] = useState("");
  // const [networkDisconnect, setNetworkDisconnect] = useState(false);
  const [incorrectNetwork, setIncorrectNetwork] = useState(false);
  // const [postFlag, setPostFlag] = useState(false);
  // const [networkNames, setNetworkName] = useState(NETWORK_NAME);
  // const [gas, setGaslimit] = useState(5000000);
  const gas = 5000000;

  const [balance, setBalance] = useState(undefined);
  const [winKoef, setWinKoef] = useState(1); //кофициент выигрыша
  const [walletConnectedFlag, setWalletConnectedFlag] = useState(false);
  const [buttonPlay, setButtonPlay] = useState(false); //отображает
  const [isLoading, setIsLoading] = useState(false); // 'Play Now' clicked, loading...
  const [hasPlayed, setHasPlayed] = useState(); // confirmed in MetaMask, waiting results...
  const [gameResult, setGameResult] = useState({});
  const [playerChoice, setPlayerChoice] = useState([0]);
  const [won, setWon] = useState(true); // выиграл или проиграл
  const networkNames = NETWORK_NAME; //Обязательно поменять по всему проекту на нормально
  const [connectError, setConnectError] = useState(false);
  const [probability, setProbability] = useState();
  const [winAmount, setWinAmount] = useState("0"); // сколько выиграешь
  const [GameWasPlayed, setGameWasPlayed] = useState(false); // игра отыграна


  function setNewPlayerChoice(){

  }
  // function changeLinkWin() {
  //   history.push("/win");
  // }
  // function changeLinkLose() {
  //   history.push("/lose");
  // }

  // let urlItemWin = window.location.href;
  // let urlItemLose = window.location.href;

  window.scrollTo(0, 0);

  // (function scrolltotop () {
  //   window.scrollTo(0, 0);
  // })();

  const gameCalc = new GameCalculate();
  const saveGamblerChoice = new SaveGamblerChoice(gameCalc)

  let urlItem = window.location.href;
  const localS = async () => {
    localStorage.setItem("local-storage-modulo-data", modulo);
  };
  localS();

  const placeBet = async (betMask, modulo, amount) => {
    setIsLoading(true);
    const response = await axios.get(`${PORT}/api/bet/`);
    const { blockNumber, commit, r, s } = response.data;

    let result;

    try {
      result = await contract.methods
        .placeBet(
          betMask,
          modulo,
          blockNumber,
          commit,
          Buffer.from(r),
          Buffer.from(s)
        )
        .send({
          from: accounts[0],
          value: String(web3.utils.toWei(String(amount), "ether")),
        });

      const postData = {
        //from UI
        betOn: playerChoice,
        modulo,
        bet: amount,

        //from front calculation
        betMask,
        // from web3
        addressFrom: accounts[0],
        // from placeBet trx
        betTrxHash: result.transactionHash,
        betBlockHash: result.blockHash,
        // from first request
        commit, //
      };

      const response = await axios.post(`${PORT}/api/bet/`, postData);
      if (response.status === 500) {
        setIsLoading(false);
        setConnectError(true);
      } else {
        const {
          winLose,
          winCombination,
          betOn,
          game_payment,
          jackpot,
          jackpot_payment,
        } = response.data;

        let betData = { ...gameResult, ...response.data };

        setGameResult({ ...betData });

        sessionStorage.setItem("playerChoice", betOn);

        sessionStorage.setItem("gameResult", winCombination);

        setWon(winLose);
        setIsLoading(false);
        setHasPlayed(true);

        if (typeof InstallTrigger !== "undefined") {
          // setTimeOut for FireFox
          setTimeout(() => {
            setGameWasPlayed(!GameWasPlayed);
            updateGamblerBalance();
          }, 2500);
        } else {
          setGameWasPlayed(!GameWasPlayed);
          updateGamblerBalance();
        }
        setIsLoading(false);
        setGameWasPlayed(!GameWasPlayed);
      }

    } catch (e) {
      setConnectError(true); // Вывод сообщения об ошибки когда подключение к кошельку не удалось

      if (e.code === 4001) {
        // если код ошибки равен 4001
        setConnectError(false); // не показывать сообщение об ошибке
      } else if (e.code === -32602) {
        setWalletConnectedFlag(false);
        setButtonPlay(false);
        setConnectError(false);
      }
      setIsLoading(false);
    }
  };

  async function updateGamblerBalance() {
    const newGamblerBalance = await web3.eth.getBalance(accounts[0]);

    setWeb3AndContractCredentials({

      ...web3AndContractCredentials,
      gamblerBalance: newGamblerBalance,
    });
  }

  return (
    <Router>
      <div className="App">
        <BlockchainContext.Provider
          value={{
            ...blockchainContext,
            PORT,
            modulo,
            setModulo,
            balance, setBalance,
            // web3,
            // setWeb3,
            // accounts,
            // setAccounts,
            // contract,
            // setContract,
            //////////////////////////////////////////////
            // owner,
            // setOwner,
            // web3AndContractCredentials[web3],
            web3,
            accounts,
            contract,
            lockedInBets,
            contractBalance,
            gamblerBalance,
            maxProfit,
            jackpotSize,
            ////////////////////////////////////////////
            placeBet,
            // jackpotSize,
            // setJackpotSize,
            // lockedInBets,
            // setLockedInBets,
            walletConnectedFlag,
            setWalletConnectedFlag,
            winKoef,
            setWinKoef,
            buttonPlay,
            setButtonPlay,
            incorrectNetwork,
            setIncorrectNetwork,
            networkNames,
            gas,
            gameResult,
            CONTRACT_ADDRESS,
            playerChoice,
            setPlayerChoice,
            web3AndContractCredentials,
            setWeb3AndContractCredentials,
            connectError,
            setConnectError,
          }}
        >
          <Switch>
            <BetContext.Provider
              value={{
                playerChoice,
                setPlayerChoice,
                gameResult,
                setGameResult,
                hasPlayed,
                winKoef,
                setWinKoef,
                setHasPlayed,
                gameCalc,
                saveGamblerChoice,
                modulo,
                setWinAmount
              }}
            >
              <TopHeader />
              <Header
                switchNetBTN={NET.SWITCH_BUTTON}
                setModulo={setModulo}
                modulo={modulo}
                setHasPlayed={setHasPlayed}
                setConnectError={setConnectError}
              />
              <Route exact path="/">
                <WelcomePage
                  setModulo={setModulo}
                  modulo={modulo}
                  ropsten={ropsten}
                />
              </Route>

              <Route path="/gamepage">
                <GamePanel
                  setModulo={setModulo}
                  modulo={modulo}
                  hasPlayed={hasPlayed}
                  setHasPlayed={setHasPlayed}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  playerChoice={playerChoice}
                  setPlayerChoice={setPlayerChoice}
                  won={won}
                  setWon={setWon}
                  winAmount={winAmount}
                  setWinAmount={setWinAmount}
                  GameWasPlayed={GameWasPlayed}
                  gamblerBalance={balance}
                  ropsten={ropsten}
                  connectError={connectError}
                  probability={probability}
                  setProbability={setProbability}
                />
              </Route>
            </BetContext.Provider>
            <Route exact path="/tos">
              <TOS />
            </Route>
            <Route exact path="/faq">
              <FAQ contract={CONTRACT_ADDRESS} />
            </Route>
            <Route path="*">
              <Page404 />
            </Route>
          </Switch>
          <Footer
            baseScanUrl={BASE_SCAN_URL}
            setModulo={setModulo}
            setHasPlayed={setHasPlayed}
            gamblerBalance={balance}
            networkName={networkNames}
            contract={CONTRACT_ADDRESS}
            balance={balance}
            setBalance={setBalance}
            setConnectError={setConnectError}
          />
        </BlockchainContext.Provider>
      </div>
    </Router>
  );
}

export default App;
