import React from 'react'
import NavBar from "./NavBar"

                
export default function Header({setModulo, modulo, activeLink, setActiveLink, setHasPlayed, setWarning, switchNetBTN, setConnectError }){
    return (
        <div className="header">
           <NavBar 
                switchNetBTN={switchNetBTN}
                setModulo={setModulo}
                modulo={modulo}
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                setHasPlayed={setHasPlayed}
                setWarning={setWarning}
                setConnectError={setConnectError}
            />
           
        </div>
    )
}  